<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加客户等级</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <a-tag color="#108ee9">
                    <span v-for="item in status_list" :key="item.key" v-show="item.key == data.text">{{item.value}}</span>
                </a-tag>
            </template>

            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import TableList from "@/components/TableList";
import { getCustomerLevel, changeCustomerLevel } from '@/api/customer'

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "客户等级名称",
        dataIndex: "name",
    },
    {
        title: "客户数",
        dataIndex: "num",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "备注",
        dataIndex: "remark",
    },

    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];



export default {
    name: "Index",
    components: {
        EditPop,
        TableList
    },
    data() {
        return {
            get_table_list: getCustomerLevel,
            columns,
            visible: false,
			status_list:this.$config.status_list,
            form_data: {
                title: "添加",
                list: [
					{
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: "text",
                        name: "name",
                        title: "客户等级",
                        options: {}
                    },
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: this.$config.status_list,
                        options: {},
                    },
                    {
                        type: "textarea",
                        name: "remark",
                        title: "说明",
                        options: {}
                    }
                ]
            },
        };
    },
    methods: {
        add() {
			this.form_data.title = "添加"
			this.form_data.list.forEach(item => {
                item.options.initialValue = '';
            })
            this.visible = true;
        },
        edit(data) {
            this.form_data.title = "编辑"
            this.form_data.list.forEach(item => {
                item.options.initialValue = data[item.name];
            })
            this.visible = true;
        },
        submit(e) {
			if(!e.values.id){
				e.values.id = '';
			}
            changeCustomerLevel({
                data: e.values,
                info: true,
            }).then(res=>{
				this.visible = false;
				this.$refs.list.get_list();
			})
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>